import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/index.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Player from "./views/pages/Player"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(	
	<BrowserRouter>
		<Routes>
			<Route path='/:id' element={<Player />} />
			<Route path="*" element={<Navigate to="/index" replace />} />
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
