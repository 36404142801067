const microURL = {
    ms1: "micro1.",
    ms2: "micro2.",
    ms3: "micro3.",
    ms4: "micro4.",
    ms5: "micro5.",
    ms6: "micro5."
  };
  const serverURL = { http: "http://", https: "https://" };
  // export const mainUrl2 = (props) => { // const appURL2 = props// }
  const mainURL = { localURL: "roundvue.com/", liveURL: "roundview.io/" }; 
  export const appURL = mainURL.liveURL;
  
  // export const previewURL = appURL === "roundclicks.com/" ? `https://app.${appURL}` : `https://alpha.${appURL}`;
  
  export const ms1URL = serverURL.https + microURL.ms1 + appURL;
  export const ms2URL = serverURL.https + microURL.ms2 + appURL;
  export const ms3URL = serverURL.https + microURL.ms3 + appURL;
  export const ms4URL = serverURL.https + microURL.ms4 + appURL; 
  export const ms5URL = serverURL.https + microURL.ms5 + appURL;
  export const ms6URL = serverURL.https + microURL.ms6 + appURL;