import Path from './Path';
import StatusValidation from './StatusValidation';

export function PostData(pathValue, type, userData, formData = false) {
	return new Promise((resolve, reject) => {
		let header	= {}
		if( !formData ){
			header['Content-Type']	= 'application/json'
		}
		fetch(Path(pathValue) + type, {
			method: 'POST',
			body: formData ? userData : JSON.stringify(userData),
			headers: header,
		}).then((response) => StatusValidation(response)
		).then((responseJson) => {
			resolve(responseJson);
		}).catch((error) => {
			console.log(error)
			reject(error);
		});
	});
}

export function GetData(pathValue, type, userData) {
	return new Promise((resolve, reject) => {
		fetch(Path(pathValue) + type, {
			method: 'Get',
			body: JSON.stringify(userData),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((response) => StatusValidation(response)
		).then((responseJson) => {
			resolve(responseJson);
		}).catch((error) => {
			reject(error);
		});
	});
}

export function PutData(pathValue, type, userData, formData = false) {
	return new Promise((resolve, reject) => {
		let header	= {}
		if( !formData ){
			header['Content-Type']	= 'application/json'
		}
		fetch(Path(pathValue) + type, {
			method: 'PUT',
			body: formData ? userData : JSON.stringify(userData),
			headers: header,
		}).then((response) => StatusValidation(response)
		).then((responseJson) => {
			resolve(responseJson);
		}).catch((error) => {
			console.log(error)
			reject(error);
		});
	});
}

export function DeleteData(pathValue, type, userData, formData = false) {
	return new Promise((resolve, reject) => {
		let header	= {}
		if( !formData ){
			header['Content-Type']	= 'application/json'
		}
		fetch(Path(pathValue) + type, {
			method: 'DELETE',
			body: formData ? userData : JSON.stringify(userData),
			headers: header,
		}).then((response) => StatusValidation(response)
		).then((responseJson) => {
			resolve(responseJson);
		}).catch((error) => {
			console.log(error)
			reject(error);
		});
	});
}