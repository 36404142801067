import { appURL } from "./Appurl";
const mainPath = appURL;
const Path = pathValue => {
	switch (pathValue) {
		case "ms1":			
			return `https://micro1.${mainPath}`;
		case "ms2":			
			return `https://micro2.${mainPath}`;
		case "ms3":			
			return `https://micro3.${mainPath}`;
		case "ms4":			
			return `https://micro4.${mainPath}`;
		case "ms6":
			return `https://micro6.${mainPath}`;
		default:			
			return `https://micro1.${mainPath}`;
	}
};
export default Path;